import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ExisteView from "../views/ExisteView.vue";
import LeadView from "../views/NewLeadView.vue";
import AlertView from "../views/AlertView.vue";
import RecordView from "../views/NewRecordView.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: "Aliquam - Solicitud de Cartas de Autorizacion" }
  },
  {
    path: "/solicitar_tramite/:rfc",
    name: "solicitarTramite",
    component: RecordView,
    meta: { title: "Aliquam - Solicitud de Cartas de Autorizacion" }
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: { title: "Aliquam - Solicitud de Cartas de Autorizacion" }
  },
  {
    path: "/existe_:rfc",
    name: "existe",
    component: ExisteView,
    meta: { title: "Aliquam - Solicitud de Cartas de Autorizacion" },
  },
  {
    path: "/solicitud/:rfc",
    name: "solicitud",
    component: LeadView,
    meta: { title: "Aliquam - Solicitud de Cartas de Autorizacion" }
  },
  {
    path: "/onSuccess",
    name: "onSuccess",
    component: AlertView,
    meta: { title: "Aliquam - Solicitud de Cartas de Autorizacion" },
    props: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "ALIQUAM";
  next();
});

export default router;
