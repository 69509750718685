<template>
  <div class="small-container-form">
    <div class="logo-onboarding">
      <v-img
        class="logo-index"
        src="../assets/img/logo.png"
        alt="Logo Aliquam"
        id="logo"
      ></v-img>
      <h4>Sistema de solicitud de Cartas de Autorización</h4>
    </div>

    <div v-if="showSuccessMessage" class="alert-box success">
      <h3>Solicitud enviada con éxito</h3>
      <br />
      <h5>{{ successMessage }}</h5>
      <br />
    </div>
    <div v-if="showExistsMessage" class="alert-box warning">
      <h3>El trámite actual ya existe.</h3>
      <br />
      <h5>{{ successMessage }}</h5>
      <br />
    </div>
    <div v-if="showErrorMessage" class="alert-box error">
      <h3>Hubo un error</h3>
      <br />
      <h5>Por favor, inténtelo de nuevo más tarde.</h5>
      <br />
    </div>
    <br />

    <div v-if="!emailSubmitted">
      <div class="alert-box info">
        Para poder solicitar el tramite, le pedimos que ingrese una contraseña para el registro de su cuenta Aliquam.
      </div>
      <v-text-field v-model="password" label="Contraseña" type="password" variant="outlined"></v-text-field>
      <v-text-field v-model="confirmPassword" label="Confirmar Contraseña" type="password" variant="outlined"></v-text-field>
      <v-btn @click="submitEmail" class="mb-3 mt-3 submit-button kiosko-btn">
        Solicitar Trámite
      </v-btn>

      <div v-if="loading" class="loader"></div>
    </div>

    <br />
    <router-link to="/">Regresar al inicio</router-link>
  </div>
</template>

<script setup>
import { detonarServiciosRecordsCDA } from "@/router/services/apiService";
import { ref } from "vue";
import { useRoute } from "vue-router";

const showSuccessMessage = ref(false);
const showErrorMessage = ref(false);
const showExistsMessage = ref(false);
const successMessage = ref("");
const route = useRoute();
const rfc = ref(route.params.rfc);

const password = ref("");
const confirmPassword = ref("");
const emailSubmitted = ref(false);
const loading = ref(false);

const submitEmail = () => {
  if (password.value === confirmPassword.value) {
    emailSubmitted.value = true;
    loading.value = true;
    crearTramiteLead();
  } else {
    alert("Las contraseñas no coinciden. Por favor, inténtelo de nuevo.");
  }
};

const crearTramiteLead = async () => {
  try {
    let data = await detonarServiciosRecordsCDA(rfc.value, password.value);
    if (data && data.error) {
      console.error("Error received:", data.error);
      showErrorMessage.value = true;
    } else if (data && data.exists) {
      showExistsMessage.value = true;
    } else {
      showSuccessMessage.value = true;
    }

    const today = new Date();
    const dayOfWeek = today.getDay();

    if (dayOfWeek === 6 || dayOfWeek === 0) {
      successMessage.value = "Nos comunicaremos con usted el lunes lo más pronto posible.";
    } else {
      successMessage.value = "Nos comunicaremos con usted lo más pronto posible.";
    }
  } catch (error) {
    console.error(error);
    showErrorMessage.value = true;
  } finally {
    loading.value = false;
  }
};
</script>

<style>
.small-container-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.alert-box {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.alert-box.success {
  background-color: #dff0d8;
  color: #3c763d;
}

.alert-box.warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
}

.alert-box.error {
  background-color: #f2dede;
  color: #a94442;
}

.alert-box.info {
  background-color: #d9edf7;
  color: #31708f;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
