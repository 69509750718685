import axios from "axios";

export async function getDoctor(rfc) {
  const url = `${process.env.VUE_APP_LOCALHOST}/api/getDoctor_${rfc}`;
  try {
    const response = await axios.get(url);
    const data =
      typeof response.data === "string"
        ? JSON.parse(response.data)
        : response.data;
    return data; // Directly return the data from the response
  } catch (error) {
    console.log(error);
    return []; // Return an empty array or any other appropriate fallback
  }
}

export async function getInsurance() {
  const url = `${process.env.VUE_APP_LOCALHOST}/api/getInsurance`;
  try {
    const response = await axios.get(url);
    const data =
      typeof response.data === "string"
        ? JSON.parse(response.data)
        : response.data;
    return data; // Directly return the data from the response
  } catch (error) {
    console.log(error);
    return []; // Return an empty array or any other appropriate fallback
  }
}

export async function getPlaceOfProcedure() {
  const url = `${process.env.VUE_APP_LOCALHOST}/api/getPlaceOfProcedure`;
  try {
    const response = await axios.get(url);
    const data =
      typeof response.data === "string"
        ? JSON.parse(response.data)
        : response.data;
    return data; // Directly return the data from the response
  } catch (error) {
    console.log(error);
    return []; // Return an empty array or any other appropriate fallback
  }
}

export async function getParticipation() {
  const url = `${process.env.VUE_APP_LOCALHOST}/api/getParticipation`;
  try {
    const response = await axios.get(url);
    const data =
      typeof response.data === "string"
        ? JSON.parse(response.data)
        : response.data;
    return data; // Directly return the data from the response
  } catch (error) {
    console.log(error);
    return []; // Return an empty array or any other appropriate fallback
  }
}

export async function fetchData(rfc) {
  const user = await getDoctor(rfc);
  const places = await getPlaceOfProcedure();
  const partners = await getInsurance();
  const participation = await getParticipation();

  const args = {
    user: user,
    places: places,
    partners: partners,
    participations: participation,
    captcha_public_key: process.env.VUE_APP_CAPTCHA_PUBLIC_KEY,
  };
  return args;
}

export async function createDoctor(data) {
  const url = `${process.env.VUE_APP_LOCALHOST}/api/createDoctor`; // Use process.env to access environment variables

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" }, // Correct header for JSON data
      body: JSON.stringify(data), // Send the data as a JSON string
    });

    if (!response.ok) {
      throw new Error(
        `Network response was not ok, status: ${response.status}`
      );
    }

    return await response.json(); // Parse and return the JSON response
  } catch (error) {
    console.error("Error during createDoctor:", error);
    throw error; // Rethrowing the error or handle it as needed
  }
}

export async function createLead(formData) {
  const url = `${process.env.VUE_APP_LOCALHOST}/api/createLead`;
  const body = new URLSearchParams();

  // Iterate over formData to handle special cases for objects
  for (const [key, value] of Object.entries(formData)) {
    // If the value is an object and has an 'id' property, append the id
    if (typeof value === "object" && value !== null && "id" in value) {
      body.append(key, value.id);
    } else {
      // For all other cases, append the value directly
      body.append(key, value);
    }
  }

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body,
    });

    if (!response.ok) {
      throw new Error(
        `Network response was not ok, status: ${response.status}`
      );
    }

    // Parse and return the JSON response
    return await response.json();
  } catch (error) {
    console.error("Error during createLead:", error);
    throw error; // Rethrowing the error or handle it as needed
  }
}

export async function detonarServiciosRecordsCDA(vat, pass) {
  const urlGetDatos = `${process.env.VUE_APP_LOCALHOST}/api/getMedicoDatosRecordsCDA/${vat}`;
  const urlCrearTramiteCDA = `${process.env.VUE_APP_LOCALHOST}/api/crearTramiteOdoo/`;

  const dataResponse = await fetch(urlGetDatos, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!dataResponse.ok) {
    throw new Error("Network response was not ok for getting data");
  }

  let dataJson = await dataResponse.json();

  // Parse dataJson if it's a string
  if (typeof dataJson === 'string') {
    // dataJson.password = pass;
    dataJson = JSON.parse(dataJson);
  }

  if (!dataJson.error) {
    // Add the pass string to the dataJson object
    dataJson.pass = pass;
    // console.log(dataJson);
    const response = await fetch(urlCrearTramiteCDA, {
      method: "POST",
      body: JSON.stringify(dataJson), // Send the updated JSON with the pass included
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok for creating Tramite");
    }

    const createResponseJson = await response.json();
    console.log(createResponseJson);
    return createResponseJson;
  }

  return dataJson;
}


// async function createSolicitarLead(data) {
//   const url = `${odoo_url}/api/create?token=${token}`; // Ensure secure management
//   const body = new URLSearchParams({
//     values: JSON.stringify(data.jsonLead), // Assuming data.jsonLead is the correct data structure
//     model: "crm.lead",
//   });

//   const response = await fetch(url, {
//     method: "POST",
//     headers: { "Content-Type": "application/x-www-form-urlencoded" }, // Adjust as necessary
//     body: body,
//   });

//   if (!response.ok) {
//     throw new Error("Network response was not ok");
//   }
//   return response.json();
// }
