<template>
  <v-container
    class="container-form"
    style="max-width: 800px; text-align: center; height: auto"
  >
    <div class="logo-onboarding">
      <div class="logo-system">
        <v-img
          src="../assets/img/logo.png"
          alt="Logo Aliquam"
          class="logo-index"
          id="logo-icon"
        ></v-img>
        <h2>Sistema de solicitud de Cartas de Autorización</h2>
      </div>
    </div>

    <v-spacer></v-spacer>

    <div v-if="userName">
      <h3>
        {{ userName }}, <br />
        detectamos que usted ya es aliado Aliquam
      </h3>
      <br />
      <h5>Por favor, genere el trámite desde su aplicación móvil</h5>
      <div class="app-links">
      <a href="https://apps.apple.com/mx/app/aliquam/id1330850449" id="apple-app-link">
        <AppleIcon />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.aliquamapp.mx" id="google-app-link">
        <GoogleIcon />
      </a>
    </div>
    </div>
    <div v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        :size="45"
        :width="10"
      ></v-progress-circular>
    </div>

    <v-spacer></v-spacer>

    <v-btn class="kiosko-btn" text to="/">Regresar al inicio</v-btn>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getDoctor } from "@/router/services/apiService"; // Adjust the path as necessary
import { useRoute } from "vue-router"; // If using Vue Router to access route params
import GoogleIcon from '../assets/img/icons/GoogleAppStoreBadge.vue'
import AppleIcon from '../assets/img/icons/AppStoreDownloadBadge.vue'

const route = useRoute();
const userName = ref(null);

onMounted(async () => {
  const rfc = route.params.rfc; // Assuming the RFC is passed as a route param
  try {
    const user = await getDoctor(rfc);
    userName.value = user[0].name; // Adjust based on the actual response structure
  } catch (error) {
    console.error("Error fetching user data:", error);
    // Handle error (e.g., show an error message)
  }
});
</script>
