<template>
  <v-container class="container-form">
    <v-form ref="form" id="crTramite">
      <div>
        <v-img
          class="logo-index"
          src="../assets/img/logo.png"
          alt="Logo Aliquam"
          id="logo"
        ></v-img>
        <h4>Sistema de solicitud de Cartas de Autorización</h4>
      </div>

      <v-text-field
        required
        label="RFC"
        v-model="rfc"
        id="vat"
        placeholder="Ingresar RFC"
        variant="outlined"
      ></v-text-field>

      <div v-show="showEmailDiv">
        <v-text-field
          required
          label="Nombre(s)"
          v-model="nombre"
          id="x_nombres"
          placeholder="Ingresar nombre(s)"
          variant="outlined"
        ></v-text-field>
        <v-text-field
          required
          label="Apellido Paterno"
          v-model="apellidoPaterno"
          id="x_apellido_paterno"
          placeholder="Ingresar apellido paterno"
          variant="outlined"
        ></v-text-field>
        <v-text-field
          required
          label="Apellido Materno"
          v-model="apellidoMaterno"
          id="x_apellido_materno"
          placeholder="Ingresar apellido materno"
          variant="outlined"
        ></v-text-field>
        <v-text-field
          required
          label="Correo Electrónico"
          v-model="email"
          id="email"
          placeholder="Ingresar correo electrónico"
          variant="outlined"
        ></v-text-field>
        <v-text-field
          required
          label="Celular"
          v-model="phone"
          id="phone"
          placeholder="Ingresar número de celular"
          variant="outlined"
        ></v-text-field>
        <v-text-field
          label="Especialidad"
          v-model="speciality"
          id="function"
          placeholder="Ingresar especialidad"
          variant="outlined"
        ></v-text-field>
      </div>

      <div class="checkbox-wrapper">
        <v-checkbox v-model="privacyAccepted" id="checkbox">
          <template v-slot:label>
            He leído y acepto el
            <a href="https://servicios.aliquam.app/facturacion/static/aviso_privacidad.pdf" target="_blank">aviso de privacidad</a>.
          </template>
        </v-checkbox>
      </div>

      <v-btn @click="checkRfc" class="kiosko-btn" v-show="!showAccountButton">
        Acceder
      </v-btn>

      <div class="loader-container" v-if="loading">
        <v-progress-circular
          color="primary"
          indeterminate
          :size="45"
          :width="10"
        ></v-progress-circular>
      </div>

      <v-btn
        v-show="showAccountButton"
        @click="createAccount"
        class="kiosko-btn"
        :disabled="loading"
      >
        Crear cuenta
      </v-btn>
    </v-form>
  </v-container>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { createDoctor, getDoctor } from "@/router/services/apiService";

const form = ref(null);
const showEmailDiv = ref(false);
const showAccountButton = ref(false);
const rfc = ref("");
const nombre = ref("");
const apellidoPaterno = ref("");
const apellidoMaterno = ref("");
const email = ref("");
const phone = ref("");
const speciality = ref("");
const privacyAccepted = ref(false);
const loading = ref(false); // Estado para controlar la carga y el botón deshabilitado

const router = useRouter();

const checkRfc = async () => {
  if (!privacyAccepted.value) {
    alert("Debes aceptar el aviso de privacidad");
    return;
  }
  try {
    const data = await getDoctor(rfc.value);
    if (data.length > 0) {
      console.log(data);
      const doctor = data[0];
      const categoryId = doctor.category_id;

      const hasExCliente = Array.isArray(categoryId)
        ? categoryId.some(cat => cat.name === "ExCliente" || cat.name === "Ex Cliente")
        : categoryId && (categoryId.name === "ExCliente" || categoryId.name === "Ex Cliente");

      if (doctor.medical_type === "medico" && !hasExCliente) {
        router.push(`/existe_${doctor.vat}`);
      } else if (((doctor.medical_type === "webapp_cda") || (doctor.medical_type === "medico") && hasExCliente)) {
        router.push(`/solicitud/${doctor.vat}`);
      } else {
        showEmailDiv.value = true;
        showAccountButton.value = true;
      }
    } else {
      showEmailDiv.value = true;
      showAccountButton.value = true;
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

const createAccount = async () => {
  if (!privacyAccepted.value) {
    alert("Debes aceptar el aviso de privacidad");
    return;
  }
  loading.value = true; // Activar el estado de carga
  const data = {
    vat: rfc.value,
    name: `${nombre.value} ${apellidoPaterno.value} ${apellidoMaterno.value}`,
    x_apellido_materno: apellidoMaterno.value,
    x_apellido_paterno: apellidoPaterno.value,
    x_nombres: nombre.value,
    email: email.value,
    phone: phone.value,
    function: speciality.value,
  };
  try {
    const result = await createDoctor(data);
    if (result.length > 0) {
      router.push(`/solicitud/${data.vat}`);
    }
  } catch (error) {
    console.error("Error:", error);
  } finally {
    loading.value = false; // Desactivar el estado de carga una vez completado
  }
};
</script>

<style>
.small-container-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.loader-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.alert-box {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.alert-box.success {
  background-color: #dff0d8;
  color: #3c763d;
}

.alert-box.warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
}

.alert-box.error {
  background-color: #f2dede;
  color: #a94442;
}

.alert-box.info {
  background-color: #d9edf7;
  color: #31708f;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
