<template>
  <v-container class="container-form">
    <v-form ref="form" id="crTramite" @submit.prevent="checkCaptcha">
      <div class="text-center">
        <v-img
          src="/static/img/logo.png"
          alt="Logo Aliquam"
          max-width="200"
        ></v-img>
        <h4>Bienvenido</h4>
        <h3 v-if="apiPopulated">{{ user[0].name }}</h3>
      </div>
      <h5 style="display: flex; margin: 2em 2em 2em 0;">Ingrese los datos del paciente para la Solicitud de su Carta de Autorización</h5>
      <v-text-field
        v-model="formData.x_nombre_paciente"
        label="Nombre(s) *"
        :rules="[v => !!v || 'Este campo es obligatorio']"
        required
        variant="outlined"
      ></v-text-field>

      <v-text-field
        v-model="formData.x_apellido_pat_paciente"
        label="Apellido paterno *"
        :rules="[v => !!v || 'Este campo es obligatorio']"
        required
        variant="outlined"
      ></v-text-field>

      <v-text-field
        v-model="formData.x_apellido_mat_paciente"
        label="Apellido materno *"
        :rules="[v => !!v || 'Este campo es obligatorio']"
        required
        variant="outlined"
      ></v-text-field>

      <v-text-field
        v-model="formData.x_fecha_nacimiento_paciente"
        label="Fecha de nacimiento"
        type="date"
        variant="outlined"
      ></v-text-field>

      <v-text-field
        v-model="formData.date_initial"
        label="Fecha de ingreso *"
        type="date"
        :rules="[v => !!v || 'Este campo es obligatorio']"
        required
        variant="outlined"
      ></v-text-field>

      <v-text-field
        v-model="formData.date_final"
        label="Fecha de egreso"
        type="date"
        variant="outlined"
      ></v-text-field>

      <v-text-field
        v-model="formData.no_folio"
        label="Folio Hospital"
        variant="outlined"
      ></v-text-field>

      <v-select
        v-model="formData.place_of_procedure"
        :items="places"
        item-title="name"
        item-value="id"
        label="Seleccionar lugar de procedimiento *"
        variant="outlined"
        :rules="[v => !!v || 'Este campo es obligatorio']"
        required
        return-object
      ></v-select>

      <v-select
        v-model="formData.medic_participation"
        :items="participations"
        item-title="name"
        item-value="id"
        label="Seleccionar participación *"
        variant="outlined"
        :rules="[v => !!v || 'Este campo es obligatorio']"
        required
        return-object
      ></v-select>

      <v-autocomplete
        v-model="formData.insurer_id"
        :items="sortedPartners"
        item-title="name"
        item-value="id"
        label="Seleccionar aseguradora *"
        variant="outlined"
        :rules="[v => !!v || 'Este campo es obligatorio']"
        required
        return-object
        v-model:search="search"
      ></v-autocomplete>

      <v-textarea
        v-model="formData.description"
        label="Observaciones"
        variant="outlined"
      ></v-textarea>

      <div class="loader-container" v-if="loading">
        <v-progress-circular
          color="primary"
          indeterminate
          :size="45"
          :width="10"
        ></v-progress-circular>
      </div>

      <v-btn
        type="submit"
        class="mb-3 mt-3 submit-button kiosko-btn"
        :disabled="loading"
      >
        Enviar solicitud
      </v-btn>
    </v-form>
  </v-container>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { fetchData, createLead } from "@/router/services/apiService";

const route = useRoute();
const router = useRouter();
const user = ref([]);
const formData = ref({
  partner_id: "",
  vat: "",
});
const places = ref([]);
const participations = ref([]);
const partners = ref([]);
const apiPopulated = ref(false);
const search = ref("");
const loading = ref(false); // Estado para controlar la carga y el botón deshabilitado

const sortedPartners = computed(() => {
  return partners.value.slice().sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
});

const fetchFormData = async () => {
  const data = await fetchData(route.params.rfc);
  if (data.user) {
    user.value = [...data.user];
    formData.value.partner_id = user.value[0].id;
    formData.value.vat = user.value[0].vat;
    apiPopulated.value = true;
  }

  if (data.places) {
    places.value = [...data.places];
  }

  if (data.partners) {
    partners.value = [...data.partners];
  }

  if (data.participations) {
    participations.value = [...data.participations];
  }
};

const checkCaptcha = async () => {
  const captchaToken = true;
  if (captchaToken) {
    await submitFormData(captchaToken);
  } else {
    alert("Captcha no fue validado.");
    console.error("Captcha validation failed");
  }
};

const submitFormData = async (captchaToken) => {
  loading.value = true; // Activar el estado de carga
  const submissionData = {
    ...formData.value,
    captchaToken,
  };

  const requiredFields = {
    x_nombre_paciente: "Nombre del paciente",
    x_apellido_pat_paciente: "Apellido paterno del paciente",
    x_apellido_mat_paciente: "Apellido materno del paciente",
    date_initial: "Fecha de Ingreso",
    place_of_procedure: "Lugar de procedimiento",
    medic_participation: "Participación del medico",
  };

  let missingFields = [];

  for (const field in requiredFields) {
    if (!formData.value[field]) {
      missingFields.push(requiredFields[field]);
    }
  }
  if (missingFields.length == 1) {
    alert(`Por favor, complete el siguiente campo: ${missingFields.join(", ")}`);
    loading.value = false; // Desactivar el estado de carga
    return;
  }
  if (missingFields.length > 1) {
    alert(`Por favor, complete los siguientes campos: ${missingFields.join(", ")}`);
    loading.value = false; // Desactivar el estado de carga
    return;
  }

  try {
    const response = await createLead(submissionData);
    const rfc_value = user.value[0].vat;
    if (response.success) {
      router.push({ name: "onSuccess", query: { isSuccess: true, rfc: rfc_value }, params: { rfc: rfc_value } });
    } else {
      router.push({ name: "onSuccess", query: { isSuccess: false, rfc: rfc_value }, params: { rfc: rfc_value } });
    }
  } catch (error) {
    console.error("Error submitting form:", error);
  } finally {
    loading.value = false; // Desactivar el estado de carga una vez completado
  }
};

onMounted(() => {
  fetchFormData();
});
</script>

<style>
.container-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.loader-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.alert-box {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.alert-box.success {
  background-color: #dff0d8;
  color: #3c763d;
}

.alert-box.warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
}

.alert-box.error {
  background-color: #f2dede;
  color: #a94442;
}

.alert-box.info {
  background-color: #d9edf7;
  color: #31708f;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
