<template>
  <v-container class="container-success">
    <div class="logo-onboarding">
      <div class="logo-system">
        <v-img
          src="../assets/img/logo.png"
          alt="Logo Aliquam"
          class="logo-index"
          id="logo-icon"
        ></v-img>
        <h2>Sistema de solicitud de Cartas de Autorización</h2>
      </div>
    </div>
    <div v-if="isSuccess">
      <h3>Solicitud enviada con éxito</h3>
      <h5>
        Si en 15 minutos no recibe un correo de confirmación, por favor revise
        su carpeta de SPAM.
      </h5>
    </div>
    <div v-else>
      <h3>Ha ocurrido un error</h3>
      <h5>Por favor, intente enviar su solicitud nuevamente.</h5>
    </div>
    <v-btn @click="newCDACase" class="kiosko-btn">Solicitar otra Carta de Autorización</v-btn>
    <v-btn @click="goBack" class="kiosko-btn">Regresar al inicio</v-btn>
  </v-container>
</template>

<script setup>
// import { ref } from "vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

const route = useRoute();
const isSuccess = route.query.isSuccess === "true";
const rfc = route.query.rfc; // Obtén el CURP de los parámetros de la ruta
console.log(rfc, isSuccess);
// Assume success by default, or set based on actual logic
const router = useRouter();

function goBack() {
  router.push("/");
}

function newCDACase() {
  router.push(`/solicitud/${rfc}`);
}
</script>

<style scoped>
/* Add any styles here */
</style>
